<template>
  <div class="some-style">
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <strong>Workshop Roster</strong>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col sm="3">
                <b-form-group label-for="session-date-range" label="Session Start Date Within:">
                  <picker-of-dates
                    type="date"
                    id="session-date-range"
                    v-model="criteria.sessionDateRange"
                    format="MM/dd/yyyy"
                    placeholder="Select Date Range"
                    clearable
                    range />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm>
                <b-form-group label-for="search-workshops" label="Workshop(s):">
                  <b-input-group size="sm">
                    <b-select id="search-workshops" v-model="criteria.workshopIds" :options="workshopOptions" :select-size="8" multiple />
                    <b-input-group-addon>
                      <b-button @click="criteria.workshopIds = []">
                        <font-awesome-icon prefix="fas" icon="times" />
                      </b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm>
                <b-form-group label-for="search-sessions" label="Session(s):">
                  <b-input-group size="sm">
                    <b-select id="search-sessions" v-model="criteria.sessionIds" :options="sessionOptions" :select-size="8" multiple />
                    <b-input-group-addon>
                      <b-button @click="criteria.sessionIds = []">
                        <font-awesome-icon prefix="fas" icon="times" />
                      </b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="9">
                <b-form-group label-for="search-organization-type" label="Organizations:">
                  <b-input-group size="sm">
                    <b-select id="search-organization-type" v-model="criteria.organizationType" :options="organizationTypeOptions">
                      <template v-slot:first>
                        <option :value="null">-</option>
                      </template>
                    </b-select>
                    <b-input-group-addon>
                      <b-button @click="criteria.organizationType = null">
                        <font-awesome-icon icon="times" />
                      </b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>

                <b-form-group label-for="search-organizations">
                  <b-input-group size="sm">
                    <b-select id="search-organizations" v-model="criteria.organizationIds" :options="organizationOptions" :select-size="8" multiple />
                    <b-input-group-addon>
                      <b-button @click="criteria.organizationIds = []">
                        <font-awesome-icon prefix="fas" icon="times" />
                      </b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group label="District(s):">
                  <b-input-group size="sm">
                    <b-select v-model="criteria.districtIds" multiple :select-size="6" :options="districtOptions" />
                    <b-input-group-addon>
                      <b-button @click="criteria.districtIds = []">
                        <font-awesome-icon prefix="fas" icon="times" />
                      </b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <b-form-group label-for="search-firstname" label="Attendee's First Name:">
                  <b-input-group size="sm">
                    <b-input id="search-firstname" placeholder="First Name" v-model="criteria.firstname" trim />
                    <b-input-group-addon>
                      <b-button @click="criteria.firstname = null"><font-awesome-icon icon="times" /></b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group label-for="search-lastname" label="Attendee's Last Name:">
                  <b-input-group size="sm">
                    <b-input id="search-lastname" placeholder="Last Name" v-model="criteria.lastname" trim />
                    <b-input-group-addon>
                      <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times" /></b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group label-for="search-ubcid" label="Attendee's UBC ID:">
                  <b-input-group size="sm">
                    <b-input id="search-ubcid" v-model="criteria.ubcId" placeholder="UBC ID" />
                    <!--                                        <ubc-id id="search-ubcid" v-model="criteria.ubcId" input-class="form-control form-control-sm"/>-->
                    <b-input-group-addon>
                      <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times" /></b-button>
                    </b-input-group-addon>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-button-group size="sm">
              <b-button class="mr-2" variant="primary" @click="doSearch">Search</b-button>
              <b-button class="mr-2" variant="secondary" @click="showFieldSelection">Select Fields</b-button>
              <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
              <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
              <b-button variant="info" @click="clearFilters">Clear</b-button>
            </b-button-group>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-modal :id="fieldSelectionModalId" title="Select Fields to Include in Workshop Roster Report" hideFooter>
      <WorkshopFieldSelection :criteria="criteria" @done="fieldSelectionComplete" />
    </b-modal>

    <b-modal id="a-modal" v-model="modalShow" size="huge" scrollable title-html="<h3><strong>Workshop Roster</strong></h3>">
      <b-row>
        <b-col cols="2" offset="9" class="text-right font-italic"> Records Found: </b-col>
        <b-col class="text-right">
          {{ recordCount }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" offset="9" class="text-right font-italic"> Active Instructors: </b-col>
        <b-col class="text-right">
          {{ instructorCount }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" offset="9" class="text-right font-italic"> Active Participants: </b-col>
        <b-col class="text-right">
          {{ participantCount }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage" />
        </b-col>
        <b-col cols="8">
          <b-form-group :label="'Records Per Page: ' + perPage">
            <b-input type="range" v-model="perPage" min="10" max="100" step="10"></b-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row> Page {{ currentPage }} of {{ pages }} </b-row>
      <b-row>
        <b-table
          small
          striped
          hover
          bordered
          no-local-sorting
          stacked="md"
          head-variant="dark"
          ref="searchResultsTable"
          selectable
          select-mode="single"
          selected-variant="warning"
          :items="searchResults"
          :fields="tableFields"
          :sort-by.sync="sortKey"
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage">
        </b-table>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage" />
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <b-button-group size="sm">
          <b-button class="mr-2" variant="secondary" @click="showFieldSelection">Select Fields</b-button>
          <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
          <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
          <b-button variant="info" @click="modalShow = false">Close</b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import PickerOfDates from '@/components/shared/PickerOfDates';
import UbcId from '@/components/shared/UbcId.vue';
import _ from 'underscore';
import { date, mkDate } from '@/util/formatters';
import reportDao from '@/dao/report_dao';
import { WorkshopRosterCriteria, WorkshopRoster } from '@/model/report';
import WorkshopFieldSelection from './WorkshopFieldSelection';
import { errorModalOptions, errorToastOptions } from '../../../util/formatters';

@Component({
  components: {
    WorkshopFieldSelection,
    PickerOfDates,
    UbcId
  }
})
export default class WorkshopRosterView extends Vue {
  organizationOptions = [];

  criteria = new WorkshopRosterCriteria();
  results = [];

  modalShow = false;

  recordCount = 0;
  instructorCount = 0;
  participantCount = 0;

  sortKey = null;
  sortDesc = false;
  currPage = 1;
  perPage = 10;

  get tableFields() {
    const fields = [];
    if (this.criteria.includeAttendeeName) {
      fields.push({
        key: 'fullname',
        label: 'Attendee',
        sortable: true
      });
    }
    if (this.criteria.includeUbcId) {
      fields.push({
        key: 'ubcId',
        label: 'UBC ID',
        sortable: true
      });
    }
    if (this.criteria.includeVaccineDetails) {
      fields.push({
        key: 'vaccineDetails',
        label: 'COVID-19 Vaccine Verified',
        sortable: true
      });
    }
    if (this.criteria.includeTitle) {
      fields.push({
        key: 'title',
        label: 'Title',
        sortable: true
      });
    }
    if (this.criteria.includeCityState) {
      fields.push({
        key: 'cityState',
        label: 'City, State/Province',
        sortable: true
      });
    }
    if (this.criteria.includeWorkshop) {
      fields.push({
        key: 'workshopName',
        label: 'Workshop',
        sortable: true
      });
    }
    if (this.criteria.includeStartDate) {
      fields.push({
        key: 'startDate',
        label: 'Start Date',
        sortable: true
      });
    }
    if (this.criteria.includeEndDate) {
      fields.push({
        key: 'endDate',
        label: 'End Date',
        sortable: true
      });
    }
    if (this.criteria.includeRegistrationType) {
      fields.push({
        key: 'registrationType',
        label: 'Registration Type',
        sortable: true
      });
    }
    if (this.criteria.includeOrganization) {
      fields.push({
        key: 'organizationName',
        label: 'Organization',
        sortable: true
      });
    }
    if (this.criteria.includeLocal) {
      fields.push({
        key: 'affiliate',
        label: 'Local',
        sortable: true
      });
    }
    if (this.criteria.includeUnionStatus) {
      fields.push({
        key: 'unionStatus',
        label: 'Union Status',
        sortable: true
      });
    }
    if (this.criteria.includeCouncil) {
      fields.push({
        key: 'council',
        label: 'Council',
        sortable: true
      });
    }
    if (this.criteria.includeDistrict) {
      fields.push({
        key: 'district',
        label: 'District',
        sortable: true
      });
    }
    if (this.criteria.includeAttendeeEmail) {
      fields.push({
        key: 'email',
        label: 'Attendee Email',
        sortable: true
      });
    }
    if (this.criteria.includeDateRegistered) {
      fields.push({
        key: 'registeredDate',
        label: 'Date Registered',
        sortable: true
      });
    }
    if (this.criteria.includeRegisteredBy) {
      fields.push({
        key: 'registeredBy',
        label: 'Registered By',
        sortable: true
      });
    }

    /*
        if (this.criteria.includeContractor) {
            fields.push({
                key: 'contractor',
                label: 'Contractor',
                sortable: true
            });
        }

        if (this.criteria.includeContractorFirstName) {
            fields.push({
                key: 'contractorFirstName',
                label: 'Contractor Contact First Name',
                sortable: true
            });
        }
*/

    return fields;
  }

  get fieldSelectionModalId() {
    return 'workshop-roster-field-selection-modal';
  }

  showFieldSelection() {
    this.$bvModal.show(this.fieldSelectionModalId);
  }

  fieldSelectionComplete() {
    this.$bvModal.hide(this.fieldSelectionModalId);
  }

  get currentPage() {
    if (this.currPage > this.pages) {
      return this.pages;
    } else {
      return this.currPage;
    }
  }

  set currentPage(num) {
    const page = parseInt(num, 10);
    switch (true) {
      case _.isNaN(page):
      case 0 >= page:
        this.currPage = 1;
        break;
      case page > this.pages:
        this.currPage = this.pages;
        break;
      default:
        this.currPage = page;
    }
  }

  get pages() {
    return Math.ceil(this.searchResults.length / this.perPage) || 1;
  }

  get searchResults() {
    const attendees = this.results;
    const sortedRows = _.chain(attendees)
      .sortBy((a) => {
        switch (this.sortKey) {
          case 'fullname':
            return a.fullname;
          case 'ubcId':
            return a.ubcId || '';
          case 'vaccineDetails':
            return a.vaccineVerifiedDate || '';
          case 'title':
            return a.title || '';
          case 'cityState':
            return a.cityState;
          case 'workshopName':
            return a.workshopName;
          case 'startDate':
            return a.startDate;
          case 'endDate':
            return a.endDate;
          case 'registrationType':
            return a.registrationType;
          case 'organizationName':
            return a.organizationName;
          case 'affiliate':
            return a.affiliate || '';
          case 'unionStatus':
            return a.unionStatus || '';
          case 'council':
            return a.council || '';
          case 'district':
            return a.district || '';
          case 'email':
            return a.email || '';
          case 'registeredDate':
            return a.registeredDate || '';
          case 'registeredBy':
            return a.registeredBy || '';
          case 'contractor':
            return a.contractor || '';
          default:
            return a.fullname;
        }
      })
      .value();
    return this.sortDesc ? sortedRows.reverse() : sortedRows;
  }

  setTotals(recs) {
    this.recordCount = recs.length;
    const grouped = _.groupBy(recs, (rec) => {
      return rec.registrationType;
    });
    if (grouped['Instructor']) {
      this.instructorCount = grouped['Instructor'].length;
    }
    if (grouped['Participant']) {
      this.participantCount = grouped['Participant'].length;
    }
  }

  get workshopOptions() {
    return _.map(this.$store.getters['common/workshops'], (ws) => {
      return {
        value: ws.id,
        text: ws.value
      };
    });
  }

  get sessionOptions() {
    const sessions = this.$store.getters['common/workshopSessionsWorkshopInstanceID'];
    const options = _.map(sessions, (s) => {
      return {
        text: s.value,
        value: s.id
      };
    });
    return options;
  }

  @Watch('criteria.workshopIds')
  async workshopsSelected(workshopIds) {
    this.criteria.sessionIds = [];
    await this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', workshopIds).catch((error) => {
      this.$bvToast.toast(error.message, errorToastOptions);
    });
  }

  get organizationTypeOptions() {
    return [
      {
        value: 'trust-fund',
        text: 'Trust Fund(s)'
      },
      {
        value: 'training-center',
        text: 'Training Center(s)'
      },
      {
        value: 'council',
        text: 'Council(s)'
      },
      {
        value: 'affiliate',
        text: 'Local(s)'
      },
      {
        value: 'sct-contractor',
        text: 'Contractor(s)'
      },
      {
        value: 'other',
        text: 'Other(s)'
      }
    ];
  }

  @Watch('criteria.organizationType')
  organizationTypeSelected(orgType) {
    this.criteria.organizationIds = [];
    this.organizationOptions = [];
    switch (orgType) {
      case 'trust-fund':
        this.loadTrustFunds();
        break;
      case 'training-center':
        this.loadTrainingCenters();
        break;
      case 'council':
        this.loadCouncils();
        break;
      case 'affiliate':
        this.loadLocals();
        break;
      case 'sct-contractor':
        this.loadContractors();
        break;
      case 'other':
        this.loadOrganizations();
        break;
      default:
        // nuthin'
        break;
    }
  }

  loadTrainingCenters() {
    this.organizationOptions = _.map(this.$store.getters['common/trainingCenters'], (tc) => {
      return {
        value: tc.id,
        text: tc.value
      };
    });
  }

  loadTrustFunds() {
    this.organizationOptions = _.map(this.$store.getters['common/trustFunds'], (tf) => {
      return {
        value: tf.id,
        text: tf.value
      };
    });
  }

  loadCouncils() {
    const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);
    this.organizationOptions = _.map(councils, (cs, district) => {
      return {
        label: district,
        options: _.map(cs, (c) => {
          return {
            value: c.displayId,
            text: sprintf('%s (%s)', c.name, c.displayId)
          };
        })
      };
    });
  }

  loadLocals() {
    const locals = _.groupBy(this.$store.getters['common/locals'], (l) => sprintf('%s - %s', l.districtName, l.councilName));
    this.organizationOptions = _.map(locals, (ls, districtCouncil) => {
      return {
        label: districtCouncil,
        options: _.map(ls, (local) => {
          return {
            value: local.displayId,
            text: sprintf('%s - %s, %s', local.displayId, local.city, local.state)
          };
        })
      };
    });
  }

  loadContractors() {
    this.organizationOptions = _.map(this.$store.getters['organizations/getContractors'], (c) => {
      return {
        value: c.id,
        text: c.name
      };
    });
  }

  loadOrganizations() {
    this.organizationOptions = _.map(this.$store.getters['organizations/getOrganizations'], (c) => {
      return {
        value: c.id,
        text: c.name
      };
    });
  }

  get districtOptions() {
    return _.map(this.$store.getters['common/districts'], (d) => {
      return {
        value: d.id,
        text: d.name
      };
    });
  }

  clearFilters() {
    this.criteria.clear();
    this.results = [];
  }

  async doSearch() {
    if (!this.isReady()) {
      return;
    }
    try {
      const attendees = await reportDao.getWorkshopRoster(JSON.stringify(this.criteria.serialize()));
      this.results = attendees;
      this.setTotals(attendees);
      this.modalShow = true;
    } catch (error) {
      await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
    }
  }

  async getCsv() {
    if (!this.isReady()) {
      return;
    }
    try {
      await download('POST', '/api/v1/export/workshop-roster', JSON.stringify(this.criteria.serialize()));
    } catch (error) {
      await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
    }
  }

  async getPdf() {
    try {
      await download('POST', '/api/v1/report/workshop-roster', JSON.stringify(this.criteria.serialize()));
    } catch (error) {
      await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
    }
  }

  isReady() {
    if (this.criteria.isEmpty()) {
      this.$bvModal.msgBoxOk('Please enter at least one search criteria.', {
        size: 'sm',
        buttonSize: 'sm'
      });
      return false;
    }
    return true;
  }

  destroyed() {
    //Clear any prior workshop sessions
    this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', []).catch((error) => {
      this.$bvToast.toast(error.message, errorToastOptions);
    });
  }

  async mounted() {
    this.$store.commit('addBreadcrumb', Breadcrumb.create('Workshop Roster', null, true));
    //Clear any prior workshop sessions
    this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', []).catch((error) => {
      this.$bvToast.toast(error.message, errorToastOptions);
    });
    _([
      'common/loadWorkshops',
      'common/loadTrustFunds',
      'common/loadTrainingCenters',
      'common/loadCouncils',
      'common/loadLocals',
      'organizations/loadContractors',
      'organizations/loadOrganizations',
      'common/loadDistricts'
    ]).each((action) => {
      this.$store.dispatch(action).catch((error) => {
        this.$bvToast.toast(error.message, errorToastOptions);
      });
    });
    // clear out 'count' fields when a-modal is closed (search results)
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'a-modal') {
        this.recordCount = 0;
        this.instructorCount = 0;
        this.participantCount = 0;
      }
    });
  }
}
</script>
<style>
@media (min-width: 992px) {
  .modal-huge {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: auto;
  }
}
</style>
