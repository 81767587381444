<template>
  <div>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeAttendeeName">Attendee Name</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeUbcId">UBC ID</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeVaccineDetails">COVID-19 Vaccine Verification</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeTitle">Title</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeCityState">City and State/Province</b-form-checkbox>
      </b-col>
      <b-col>
        <!--Always include workshop fields, this is a workshop roster!!-->
        <b-form-checkbox v-model="criteria.includeWorkshop" disabled>Workshop</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeStartDate" disabled>Start Date</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeEndDate" disabled>End Date</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeRegistrationType">Registration Type</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeOrganization">Organization</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeLocal">Local</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeUnionStatus">Union Status</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeCouncil">Council</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeDistrict">District</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeAttendeeEmail">Attendee Email</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeDateRegistered">Date Registered</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeRegisteredBy">Registered By</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeMobilePhone">Attendee Mobile Phone</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeHasPicture">Attendee Picture</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeUsername">Attendee Username</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeMentorName">Mentor Name</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeMentorEmail">Mentor Email</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeContractor">Contractor Name</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeContractorFirstName">Contractor Contact First Name</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeContractorLastName">Contractor Contact Last Name</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeContractorTitle">Contractor Contact Title</b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="criteria.includeContractorPhone">Contractor Contact Phone Number</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="criteria.includeContractorEmail">Contractor Contact Email</b-form-checkbox>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <b-button-group class="float-right">
          <b-button variant="primary" @click="criteria.setActiveFlagFields(true)">Select All</b-button>
          <b-button variant="danger" @click="criteria.setActiveFlagFields(false)">Unselect All</b-button>
          <b-button variant="success" @click="save">Save and Close</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkshopRosterCriteria } from '@/model/report';

@Component({
  components: {}
})
export default class WorkshopRosterView extends Vue {
  @Prop({ type: WorkshopRosterCriteria, default: () => new WorkshopRosterCriteria() }) criteria;

  async save() {
    //Validate field selection
    if (!this.criteria.isValid) {
      return await this.$bvModal.msgBoxOk('Please select a minimum of one field to proceed.');
    }

    this.$emit('done');
  }
}
</script>

<style></style>
